import { Box, Checkbox, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHardDeleteProcess, useRestoreProcess } from '../../hooks/processHooks';
import DeleteWarningModal from '../../Modals/DeleteWarningModal';
import CustomTable from '../commonComponents/Table';
import CustomModalButton from '../commonComponents/CustomModalButton';
import RestoreIcon from '../../assets/svg/RestoreIcon';
import {
  useDeleteAllDeletedItems,
  useGetDeletedItems,
  useRestoreAllDeletedItems,
  useRestoreSelectedDeletedItems,
} from '../../hooks/DeleteItemsHooks';
import moment from 'moment';
import RestoreWarningModal from '../../Modals/RestoreWarningModal';
import {
  useHardDeletePart,
  useHardDeleteTool,
  useRestoreDeleteTool,
  useRestorePart,
} from '../../hooks/partHooks';
import { useHardDeleteMachine, useRestoreMachine } from '../../hooks/machineHooks';
import { useHardDeleteProduct, useRestoreProduct } from '../../hooks/productHooks';
import { useHardDeleteWorkorder, useRestoreWorkorder } from '../../hooks/workorderHooks';
import { useHardDeleteLine, useRestoreLine } from '../../hooks/lineHooks';
import { useHardDeleteDepartment, useRestoreDepartment } from '../../hooks/departmentHooks';
import { useHardDeleteSupplier, useRestoreSupplier } from '../../hooks/supplierHooks';
import { useHardDeleteRole, useRestoreRole } from '../../hooks/userRoleHook';
import { useHardDeleteDesktopUser, useRestoreDesktopUser } from '../../hooks/userManagementHooks';
import { useHardDeleteMobileUser, useRestoreMobileUser } from '../../hooks/workerManagementHooks';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import RestoreAll from '../../assets/svg/RestoreAll';
import DeleteIcon_new from '../../assets/svg/DeleteIcon_new';
import DeletedItemDeleteIcon from '../../assets/svg/DeletedItemDeleteIcon';
import CheckboxIcon from '../../assets/svg/CheckboxIcon';
import CheckboxFilledIcon from '../../assets/svg/CheckboxFilledIcon';
import RestoreSelectItemWarningModal from '../../Modals/RestoreSelectedItemWarningModal';
import { useHardDeleteConsumables, useRestoreConsumables } from '../../hooks/consumablesHooks';
import { useHardDeleteAccessory, useRestoreAccessory } from '../../hooks/accessoryHooks';
import {
  useHardDeleteFinishedGoods,
  useRestoreFinishedGoods,
} from '../../hooks/finishedGoodsHooks';

const DeletedItems = () => {
  //Delete Modal
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [restoreRecord, setRestoreRecord] = useState(false);
  const [restoreSelectedRecord, setRestoreSelectedRecord] = useState<boolean>(false);
  const [currentIdForDeletion, setCurrentIdForDeletion] = useState('');
  const [currentIdForRestore, setCurrentIdForRestore] = useState('');
  const [currentType, setCurrentType] = useState<any>('');
  const typeObject = {
    Machine: '/deleted-items/machine-details',
    Tool: '/deleted-items/tool-details',
    FinishedGood: '/deleted-items/finshedgoods-details',
    RawMaterial: '/deleted-items/raw-material-details',
    Consumable: '/deleted-items/consumable-details',
    WorkOrder: '/deleted-items/completion-report',
    Accessory: '/deleted-items/accessory-details',
    Department: '/deleted-items/department-details',
    Supplier: '',
    DesktopUser: '/deleted-items/desktop-user-details',
    MobileWorker: '/deleted-items/mobile-user-details',
    UserRole: '/deleted-items/role-details',
  };

  const handleDeleteOpen = () => {
    setRestoreRecord(false);
    setDeleteRecord(true);
  };
  const handleDeleteClose = () => {
    setRestoreRecord(false);
    setDeleteRecord(false);
  };
  const handleRestoreOpen = () => {
    setDeleteRecord(false);
    setRestoreRecord(true);
  };
  const handleSelectedRestoreOpen = () => {
    setRestoreSelectedRecord(true);
  };
  const handleSelectedRestoreClose = () => {
    setRestoreSelectedRecord(false);
  };
  const handleRestoreClose = () => {
    setDeleteRecord(false);
    setRestoreRecord(false);
  };

  const {
    data: deletedItems,
    isLoading: isItemsLoading,
    isFetching: isItemsFetching,
    isSuccess: isItemsSuccess,
    isRefetching,
  } = useGetDeletedItems(true);
  const { mutate: deleteAllDeletedItems } = useDeleteAllDeletedItems();
  const { mutate: restoreAllDeletedItems } = useRestoreAllDeletedItems();
  const { mutate: restoreSelectedDeletedItems } = useRestoreSelectedDeletedItems();
  const [deletedData, setDeletedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (isItemsSuccess) {
      const listData = deletedItems?.deletedItems?.slice((currentPage - 1) * 10, currentPage * 10);
      setDeletedData(listData);
    }
  }, [isItemsSuccess, currentPage, isRefetching]);
  // All the hooks for restoring diffrent entity

  const { mutate: restorePart } = useRestorePart();
  const { mutate: restoreMachine } = useRestoreMachine();
  const { mutate: restoreProcess } = useRestoreProcess();
  const { mutate: restoreProduct } = useRestoreProduct();
  const { mutate: restoreWorkOrder } = useRestoreWorkorder();
  const { mutate: restoreLine } = useRestoreLine();
  const { mutate: restoreDepartment } = useRestoreDepartment();
  const { mutate: restoreSupplier } = useRestoreSupplier();
  const { mutate: restoreRole } = useRestoreRole();
  const { mutate: restoreDesktopUser } = useRestoreDesktopUser();
  const { mutate: restoreMobileUser } = useRestoreMobileUser();

  // All the hooks for deleting diffrent entity
  const { mutate: hardDeletePart } = useHardDeletePart();
  const { mutate: hardDeleteMachine } = useHardDeleteMachine();
  const { mutate: hardDeleteProcess } = useHardDeleteProcess();
  const { mutate: hardDeleteProduct } = useHardDeleteProduct();
  const { mutate: hardDeleteWorkOrder } = useHardDeleteWorkorder();
  const { mutate: hardDeleteLine } = useHardDeleteLine();
  const { mutate: hardDeleteDepartment } = useHardDeleteDepartment();
  const { mutate: hardDeleteSupplier } = useHardDeleteSupplier();
  const { mutate: hardDeleteRole } = useHardDeleteRole();
  const { mutate: hardDeleteDesktopUser } = useHardDeleteDesktopUser();
  const { mutate: hardDeleteMobileUser } = useHardDeleteMobileUser();
  const { mutate: hardDeleteConsumables } = useHardDeleteConsumables();
  const { mutate: hardDeleteTool } = useHardDeleteTool();
  const { mutate: hardDeleteAccessory } = useHardDeleteAccessory();
  const { mutate: hardDeleteFinishedGoods } = useHardDeleteFinishedGoods();

  const { mutate: restoreTool } = useRestoreDeleteTool();
  const { mutate: restoreConsumables } = useRestoreConsumables();
  const { mutate: restoreAccessory } = useRestoreAccessory();
  const { mutate: restoreFinishedGoods } = useRestoreFinishedGoods();

  const handleDeleteForDeletion = () => {
    if (currentIdForDeletion === 'delete') {
      deleteAllDeletedItems();
    } else {
      if (currentType === 'RawMaterial') {
        hardDeletePart(currentIdForDeletion);
      }
      // ! old conditions for future use
      // else if (currentType === 'Product') {
      //   hardDeleteProduct(currentIdForDeletion);
      // }
      else if (currentType === 'Machine') {
        hardDeleteMachine(currentIdForDeletion);
      }
      // else if (currentType === 'Process') {
      //   hardDeleteProcess(currentIdForDeletion);
      // }
      else if (currentType === 'WorkOrder') {
        hardDeleteWorkOrder(currentIdForDeletion);
      }
      // else if (currentType === 'Line') {
      //   hardDeleteLine(currentIdForDeletion);
      // }
      else if (currentType === 'Department') {
        hardDeleteDepartment(currentIdForDeletion);
      } else if (currentType === 'Supplier') {
        hardDeleteSupplier(currentIdForDeletion);
      } else if (currentType === 'UserRole') {
        hardDeleteRole(currentIdForDeletion);
      } else if (currentType === 'DesktopUser') {
        hardDeleteDesktopUser(currentIdForDeletion);
      } else if (currentType === 'MobileWorker') {
        hardDeleteMobileUser(currentIdForDeletion);
      } else if (currentType === 'Consumable') {
        hardDeleteConsumables(currentIdForDeletion);
      } else if (currentType === 'Tool') {
        hardDeleteTool(currentIdForDeletion);
      } else if (currentType === 'Accessory') {
        hardDeleteAccessory(currentIdForDeletion);
      } else if (currentType === 'FinishedGood') {
        hardDeleteFinishedGoods(currentIdForDeletion);
      }
    }
  };

  const handleRestore = () => {
    if (currentIdForRestore === 'restore') {
      restoreAllDeletedItems();
    } else {
      if (currentType === 'RawMaterial') {
        restorePart(currentIdForRestore);
      }
      // ! old conditions for future use
      // else if (currentType === 'Product') {
      //   restoreProduct(currentIdForRestore);
      // }
      else if (currentType === 'Machine') {
        restoreMachine(currentIdForRestore);
      }
      // else if (currentType === 'Process') {
      //   restoreProcess(currentIdForRestore);
      // }
      else if (currentType === 'WorkOrder') {
        restoreWorkOrder(currentIdForRestore);
      }
      // else if (currentType === 'Line') {
      //   restoreLine(currentIdForRestore);
      // }
      else if (currentType === 'Department') {
        restoreDepartment(currentIdForRestore);
      } else if (currentType === 'Supplier') {
        restoreSupplier(currentIdForRestore);
      } else if (currentType === 'UserRole') {
        restoreRole(currentIdForRestore);
      } else if (currentType === 'DesktopUser') {
        restoreDesktopUser(currentIdForRestore);
      } else if (currentType === 'MobileWorker') {
        restoreMobileUser(currentIdForRestore);
      } else if (currentType === 'Consumable') {
        restoreConsumables(currentIdForRestore);
      } else if (currentType === 'Tool') {
        restoreTool(currentIdForRestore);
      } else if (currentType === 'Accessory') {
        restoreAccessory(currentIdForRestore);
      } else if (currentType === 'FinishedGood') {
        restoreFinishedGoods(currentIdForRestore);
      }
    }
  };

  const navigate = useNavigate();
  const BtnProcessLink = {
    isBtnShow: false,
    title: '',
    linkData: [
      {
        menuItem: '',
        to: '',
      },
    ],
  };
  const [selectedItems, setSelectedItems] = useState<{ type: any; IdList: string[] }[]>([]);
  const DeletedItemsCols = [
    {
      field: 'checkboxes',
      headerName: 'Select',
      sortable: false,
      width: 110,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        // Check if the item is already selected for deletion
        const isSelectedForDeletion = selectedItems.some(
          (item) => item.type === params.row.type && item.IdList.includes(params.row.id)
        );

        return (
          <Box className='table-icons'>
            <Box>
              {/* Handle checkbox click */}
              <Checkbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxFilledIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const itemIndex = selectedItems.findIndex(
                    (item) => item.type === params.row.type
                  );

                  if (isSelectedForDeletion) {
                    // If the item is already selected for deletion, remove the ID from IdList
                    if (itemIndex !== -1) {
                      setSelectedItems((prev) => {
                        const newState = [...prev];
                        newState[itemIndex].IdList = newState[itemIndex].IdList.filter(
                          (id) => id !== params.row.id
                        );
                        return newState.filter((item) => item.IdList.length > 0);
                      });
                    }
                  } else {
                    // If the item is not selected for deletion, add the ID to IdList
                    if (itemIndex !== -1) {
                      setSelectedItems((prev) => {
                        const newState = [...prev];
                        newState[itemIndex].IdList.push(params.row.id);
                        return newState;
                      });
                    } else {
                      setSelectedItems((prev) => [
                        ...prev,
                        {
                          type: params.row.type,
                          IdList: [params.row.id],
                        },
                      ]);
                    }
                  }
                }}
                checked={isSelectedForDeletion}
              />
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'srNo',
      headerName: 'Sr. No',
      // detailsRedirection: ,
      // flex: 0.2,
      width: '65',
      renderCell: (params) => {
        const redirectionPath = typeObject[params.row.type];
        return (
          <Typography
            className='table-row-font'
            onClick={() => {
              if (params.row.type !== 'Supplier') {
                navigate(`${redirectionPath}/${params.id}`);
              }
            }}
          >
            {params.row.srNo}
          </Typography>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      detailsRedirection: 'def',
      flex: 0.7,
      renderCell: (params) => {
        const redirectionPath = typeObject[params.row.type];
        return (
          <Typography
            className='table-row-font'
            onClick={() => {
              if (params.row.type !== 'Supplier') {
                navigate(`${redirectionPath}/${params.id}`);
              }
            }}
          >
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      detailsRedirection: 'def',
      flex: 0.6,
      renderCell: (params) => {
        const redirectionPath = typeObject[params.row.type];
        return (
          <Typography
            className='table-row-font'
            onClick={() => {
              if (params.row.type !== 'Supplier') {
                navigate(`${redirectionPath}/${params.id}`);
              }
            }}
          >
            {params.row.type}
          </Typography>
        );
      },
    },
    {
      field: 'deletedOn',
      headerName: 'Deleted On',
      detailsRedirection: 'def',
      flex: 0.7,
      renderCell: (params) => {
        const redirectionPath = typeObject[params.row.type];
        return (
          <Typography
            className='table-row-font'
            onClick={() => {
              if (params.row.type !== 'Supplier') {
                navigate(`${redirectionPath}/${params.id}`);
              }
            }}
          >
            {params.row.deletedOn}
          </Typography>
        );
      },
    },
    {
      field: 'deletedBy',
      headerName: 'Deleted By',
      detailsRedirection: 'def',
      flex: 0.7,
      renderCell: (params) => {
        const redirectionPath = typeObject[params.row.type];
        return (
          <Typography
            className='table-row-font'
            onClick={() => {
              if (params.row.type !== 'Supplier') {
                navigate(`${redirectionPath}/${params.id}`);
              }
            }}
          >
            {params.row.deletedBy}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // width: 110,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Box className='table-icons'>
            <Box
              pr={'22px'}
              onClick={() => {
                setCurrentType(params.row.type);
                setCurrentIdForRestore(params.row.id);
                handleRestoreOpen();
              }}
            >
              <RestoreIcon />
            </Box>

            <Box
              onClick={() => {
                setCurrentType(params.row.type);
                setCurrentIdForDeletion(params.row.id);
                handleDeleteOpen();
              }}
            >
              <DeleteIcon_new />
            </Box>
          </Box>
        );
      },
    },
  ];
  // const DeletedItemsRows = deltedItems?.map((data, ind) => {
  const DeletedItemsRows = deletedData?.map((data: any, ind) => {
    const startIndex = (currentPage - 1) * 10;
    return {
      id: data.id,
      // srNo: ind + 1,
      srNo: startIndex + ind + 1,
      name: data.name || data.title || '-',
      type: data.type ? data.type : '-',
      deletedOn: moment(data.updatedAt).format('hh:mm A, DD MMM, YYYY'),
      deletedBy: data?.updateBy?.name ? data?.updateBy?.name : '-',
    };
  });

  const tableData = {
    columns: DeletedItemsCols || [],
    rows: DeletedItemsRows || [],
    detailsRedirection: 'noRedirection',
  };
  const BoxWrapper = styled(Box)(() => ({
    color: '#000',
    '.page-head': {
      fontWeight: '600 !important',
      fontSize: '18px !important',
      color: '#020202 !important',
      '.css-1wczmbf-MuiTypography-root': {
        lineHeight: '0',
      },
    },
    '.mr-14': {
      marginLeft: '14px',
    },
    '.header-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '16px',
      alignItems: 'center',
    },
    '.btn-background': {
      color: '#0160B9',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
  }));

  return (
    <React.Fragment>
      <BreadcrumbNavigation breadCrumbData={null} pageTitle={`Deleted Items`} backToLink={null} />
      <BoxWrapper className='main-padding'>
        <Box className='header-wrapper'>
          {DeletedItemsRows?.length ? (
            <>
              <Typography className='page-head' mb={'0px'}>
                Deleted Items List
              </Typography>
              <Box>
                <CustomModalButton
                  // variant='outlined'
                  className='btn-background'
                  onClick={() => {
                    handleSelectedRestoreOpen();
                    // setCurrentIdForRestore('restore');
                    // restoreAllDeletedItems();
                  }}
                >
                  <RestoreAll /> <span>Restore Selected Item</span>
                </CustomModalButton>
                <CustomModalButton
                  // variant='outlined'
                  className='btn-background'
                  onClick={() => {
                    handleRestoreOpen();
                    setCurrentIdForRestore('restore');
                    // restoreAllDeletedItems();
                  }}
                >
                  <RestoreAll /> <span>Restore All</span>
                </CustomModalButton>
                <CustomModalButton
                  variant='contained'
                  className='blue-bg mr-14'
                  onClick={() => {
                    handleDeleteOpen();
                    setCurrentIdForDeletion('delete');
                  }}
                >
                  <DeletedItemDeleteIcon /> <span>DELETE ALL </span>
                </CustomModalButton>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        <CustomTable
          data={BtnProcessLink}
          isOptionShow={false}
          tableData={tableData}
          isLoading={isItemsFetching || isItemsLoading}
          isNoEntityShow={tableData?.rows?.length <= 0 && isItemsSuccess}
          moduleName={'delete Item'}
          page={currentPage}
          totalPages={Math.ceil(deletedItems?.length / 10)}
          setPage={setCurrentPage}
          isFilter={false}
          iSColumnsButton={false}
        />

        {deleteRecord && currentIdForDeletion && (
          <DeleteWarningModal
            open={deleteRecord}
            handleOpen={handleDeleteOpen}
            handleClose={handleDeleteClose}
            handleDelete={handleDeleteForDeletion}
            id={currentIdForDeletion}
            isDeleteOrRestoreAll={['delete'].includes(currentIdForDeletion)}
            // params={params.row.id}
          />
        )}

        {restoreRecord && currentIdForRestore && (
          <RestoreWarningModal
            open={restoreRecord}
            // handleOpen={handleRestoreOpen}
            handleClose={handleRestoreClose}
            handleDelete={handleRestore}
            id={currentIdForRestore}
            isDeleteOrRestoreAll={['restore'].includes(currentIdForRestore)}
            // params={params.row.id}
          />
        )}
        {restoreSelectedRecord && (
          <RestoreSelectItemWarningModal
            open={restoreSelectedRecord}
            // handleOpen={handleRestoreOpen}
            handleClose={handleSelectedRestoreClose}
            selectedItems={selectedItems}
            handleDelete={restoreSelectedDeletedItems}
            id={currentIdForRestore}
            // isDeleteOrRestoreAll={['restore'].includes(currentIdForRestore)}
            // params={params.row.id}
          />
        )}
      </BoxWrapper>
    </React.Fragment>
  );
};
export default DeletedItems;
