import React, { useState } from 'react';
import { Box, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import CardHead from '../commonComponents/CardHead';
import RecentActivityCard from '../commonComponents/RecentActivityCard';
import {
  useGetActions,
  useGetDashboardActivity,
  useGetDashboardAnalytics,
  useGetDashboardIssues,
  useGetMachineData,
  useGetProductionVolumns,
} from '../../hooks/dashboardHooks';
import moment from 'moment';
import BreadcrumbNavigation from '../commonComponents/BreadCrumbNavigation';
import GlobalWrapper from '../commonComponents/GlobalWrapper';
import ActionsCard from '../commonComponents/CardInfo/ActionsCard';
import Card from '../commonComponents/CardInfo/Card';
import CardInfo from '../commonComponents/CardInfo/CardInfo';
import IssueCard from '../commonComponents/CardInfo/IssueCard';
import BarChart from '../commonComponents/BarChart';
import TableComponent from '../commonComponents/Table';
import { useGetWorkordersCurrent, useGetWorkordersOnGoing } from '../../hooks/workorderHooks';
import DateRange from './DateRange';

const DashboardWrapper = styled(Box)({
  '.actions-parent': {
    marginTop: '20px',
    '@media(min-width: 425px) and (max-width: 1024px)': {
      width: '490px',
    },
  },
  '.parent-charts': {
    marginTop: '20px',
  },
});
// ! this is new charts data for testing for future use
const barChartData = [
  {
    name: 'target',
    color: '#ABE6FF',
    data: [
      {
        name: 'd1',
        y: 10,
      },
      {
        name: 'd2',
        y: 15,
      },
      {
        name: 'd3',
        y: 19,
      },
      {
        name: 'week 4',
        y: 11,
      },
      {
        name: 'd5',
        y: 13,
      },
      {
        name: 'd6',
        y: 14,
      },
      {
        name: 'd7',
        y: 10,
      },
    ],
  },
];
const barChartData2 = [
  {
    name: 'target',
    color: '#ABE6FF',
    data: [
      {
        name: 'd1',
        y: 10,
      },
      {
        name: 'd2',
        y: 15,
      },
      {
        name: 'd3',
        y: 19,
      },
      {
        name: 'week 4',
        y: 11,
      },
      {
        name: 'd5',
        y: 13,
      },
      {
        name: 'd6',
        y: 14,
      },
      {
        name: 'd7',
        y: 10,
      },
    ],
  },
  {
    name: 'actual',
    color: '#BF0B4C',
    data: [
      {
        name: 'd1',
        y: 15,
      },
      {
        name: 'd2',
        y: 11,
      },
      {
        name: 'd3',
        y: 8,
      },
      {
        name: 'week 4',
        y: 18,
      },
      {
        name: 'd5',
        y: 18,
      },
      {
        name: 'd6',
        y: 9,
      },
      {
        name: 'd7',
        y: 10,
      },
    ],
  },
];

const Dashboard_New = () => {
  const currentDate: any = moment();
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  const [page, setPage] = useState(1);
  const [pageOnGoing, setPageOnGoing] = useState(1);
  const theme = useTheme();
  const isLGScreen = useMediaQuery(theme.breakpoints.only('lg'));
  const isMDScreen = useMediaQuery(theme.breakpoints.only('md'));
  // ! un-used Apis
  // const { data: dashboardData } = useGetDashboard(true);
  // const [dates, setDatesState] = useState({
  //   startDate: moment(weekStart).format('YYYY-MM-DD'),
  //   endDate: moment(weekEnd).format('YYYY-MM-DD'),
  // });
  // const [worker, setWorker] = useState('All');
  // const [machine, setMachine] = useState('All');
  // const { data: dashboardCharts } = useGetDashboardCharts(true, {
  //   startDate: dates.startDate,
  //   endDate: dates.endDate,
  //   worker: worker === 'All' ? '' : worker,
  //   machine: machine === 'All' ? '' : machine,
  // });
  const [analyticDates, setAnalyticDatesState] = React.useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  const [ganttDates, setGranttDatesState] = React.useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  const [productionDate, setProductionDate] = React.useState({
    startDate: moment(weekStart).format('YYYY-MM-DD'),
    endDate: moment(weekEnd).format('YYYY-MM-DD'),
  });
  const setAnalyticDates = (e, { startDate, endDate }) => {
    setAnalyticDatesState({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };
  const setGranttDates = (e, { startDate, endDate }) => {
    setGranttDatesState({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };
  const productionSetDate = (e, { startDate, endDate }) => {
    setProductionDate({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    });
  };
  const { data: dashboardIssueData } = useGetDashboardIssues(true);
  const { data: dashboardActivityData } = useGetDashboardActivity(true);
  const { data: dashboardAnalyticsData } = useGetDashboardAnalytics(true, analyticDates);
  const { data: dashboardMachineData } = useGetMachineData(true, ganttDates);
  const { data: dashboardProductionData } = useGetProductionVolumns(true, productionDate);
  const { data: actionsData } = useGetActions(true, page, 6);
  const barChartDataMachine = [
    {
      name: 'target',
      color: '#ABE6FF',
      data:
        dashboardMachineData?.length > 0
          ? dashboardMachineData?.map((machine, index) => ({
              name: machine?.name,
              y: machine?.productive,
            }))
          : [],
    },
  ];

  const barChartProductionData = [
    {
      name: 'target',
      color: '#ABE6FF',
      data:
        dashboardProductionData?.length > 0
          ? dashboardProductionData?.map((production, index) => ({
              name: production?.date,
              y: production?.target,
            }))
          : [],
    },
    {
      name: 'actual',
      color: '#BF0B4C',
      data:
        dashboardProductionData?.length > 0
          ? dashboardProductionData?.map((production, index) => ({
              name: production?.date,
              y: production?.actual,
            }))
          : [],
    },
  ];
  const { data: workOrderResponse } = useGetWorkordersCurrent(true, page);

  const { data: workOrderOnGoing } = useGetWorkordersOnGoing(true, pageOnGoing);

  const workorderData = workOrderResponse?.results;

  const rowsForCurrent = workorderData?.map((data, ind) => {
    return {
      id: data.id,
      no: data?.numericIndex,
      requestNo: data?.requestNumber ? data?.requestNumber : '-',
      name: data?.title,
      type: data?.type,
      customer: data?.customer,
      product: data?.productDetails,
      qty: data?.productDetails,
      workOrderStatus: data?.isApproved,
      deliveryDateRequested: data?.productDetails,
      started_on: data?.startedOn ? moment(data?.startedOn).format('hh:mm A, DD MMM, YYYY') : '-',
      steps_completed: `${data?.completedTaskCount} out of ${data?.totalTaskCount}`,
      waiting_on: data?.nextTaskWorker?.name ? data?.nextTaskWorker?.name : '-',
      created_by: data.createBy.name,
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      action: '',
    };
  });

  const colsForCurrent = [
    { field: 'no', headerName: 'Sr.No', flex: 0.4 },
    { field: 'name', headerName: 'Work Order Name', flex: 1 },
    {
      field: 'customer',
      headerName: 'Customer Name',
      flex: 1,
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.product?.map((product, ind) => {
              return <Typography key={ind}> {product?.product}</Typography>;
            })}
          </Box>
        );
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.qty?.map((quantity, ind) => {
              return <Typography key={ind}> {quantity?.quantity}</Typography>;
            })}
          </Box>
        );
      },
    },
    {
      field: 'started_on',
      headerName: 'started on',
      flex: 1,
    },
    {
      field: 'deliveryDateRequested',
      flex: 1.2,
      headerName: 'Due Date',
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.deliveryDateRequested?.map((date, ind) => {
              return (
                <Typography key={ind}>
                  {date?.deliveryDateRequested
                    ? moment(date?.deliveryDateRequested)?.format('hh:MM a, DD MMM, YYYY')
                    : '-'}
                </Typography>
              );
            })}
          </Box>
        );
      },
    },
  ];

  // ! table imformation
  const tableDataForCurrent = {
    rows: rowsForCurrent,
    columns: colsForCurrent,
    detailsRedirection: 'noRedirection',
  };
  const workorderOnGoingData = workOrderOnGoing?.results;
  const rowsForOnGoing = workorderOnGoingData?.map((data, ind) => {
    const startIndex = (page - 1) * 10;
    return {
      id: data.id,
      no: data?.numericIndex,
      number: data?.number ? data?.number : '-',
      name: data?.title ? data?.title : '-',
      type: data?.type ? data?.type : '-',
      customer: data?.customer ? data?.customer : '-',
      product: data?.productDetails ? data?.productDetails : '-',
      qty: data?.productDetails ? data?.productDetails : '-',
      workOrderStatus: data?.isApproved ? data?.isApproved : '-',
      // workOrderStatus: data?.workOrderStatus,
      deliveryDateRequested: data?.productDetails ? data?.productDetails : '-',
      approvedDate: data?.approvedDate
        ? moment(data?.approvedDate).format('hh:mm A, DD MMM, YYYY')
        : '-',
      steps_completed: `${data?.completedTaskCount} out of ${data?.totalTaskCount}`,
      waiting_on: data?.nextTaskWorker?.name ? data?.nextTaskWorker?.name : '-',
      created_by: data.createBy.name ? data.createBy.name : '-',
      updatedOn: data?.updatedAt ? moment(data?.updatedAt).format('hh:mm A, DD MMM, YYYY') : '-',
      action: '',
    };
  });
  const colsForOnGoing = [
    { field: 'no', headerName: 'Sr.No', flex: 0.4 },
    { field: 'name', headerName: 'Work Order Name', flex: 1 },
    { field: 'number', headerName: 'Work Order No.', flex: 1 },
    {
      field: 'customer',
      headerName: 'Customer Name',
      flex: 1,
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.product?.map((product, ind) => {
              return <Typography key={ind}> {product?.product}</Typography>;
            })}
          </Box>
        );
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.qty?.map((quantity, ind) => {
              return <Typography key={ind}> {quantity?.quantity}</Typography>;
            })}
          </Box>
        );
      },
    },
    {
      field: 'approvedDate',
      headerName: 'Started On',
      flex: 1,
    },
    {
      field: 'deliveryDateRequested',
      headerName: 'Due Date',
      flex: 1.2,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.deliveryDateRequested?.map((date, ind) => {
              return (
                <Typography key={ind}>
                  {date?.deliveryDateRequested
                    ? moment(date?.deliveryDateRequested)?.format('hh:MM a, DD MMM, YYYY')
                    : '-'}
                </Typography>
              );
            })}
          </Box>
        );
      },
    },
  ];
  //! onGoing data
  const tableDataForOngoing = {
    rows: rowsForOnGoing,
    columns: colsForOnGoing,
    // detailsRedirection: '/draft-work-order',
    detailsRedirection: '/draft-work-order',
  };
  return (
    <React.Fragment>
      <DashboardWrapper>
        <BreadcrumbNavigation breadCrumbData={null} pageTitle={`Dashboard`} backToLink={null} />
        <Box sx={{ m: 1.7 }}>
          <Grid container columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={8}>
              <Box
                className=''
                sx={{ border: '1px solid #DEDEDE', padding: '10px 10px 10px 20px' }}
              >
                <Grid container spacing={0} display={'flex'} justifyContent={'end'}>
                  <Box className='datepicker' sx={{ padding: '0px 0px 10px 0px ' }}>
                    <DateRange
                      setDatesState={setAnalyticDatesState}
                      dates={analyticDates}
                      setDates={setAnalyticDates}
                    />
                  </Box>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={4} md={2} lg={2} xl={2} className='single-job-parent'>
                    <Card
                      title='Machine Efficiency'
                      data={
                        dashboardAnalyticsData?.machinesEfficiency
                          ? dashboardAnalyticsData?.machinesEfficiency
                          : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={2} lg={2} xl={2} className='single-job-parent'>
                    <Card
                      title='Active Work Orders'
                      data={
                        dashboardAnalyticsData?.activeWorkOrders
                          ? dashboardAnalyticsData?.activeWorkOrders
                          : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={2} lg={2} xl={2} className='single-job-parent'>
                    <Card
                      title='Active Issues'
                      data={
                        dashboardAnalyticsData?.activeIssues
                          ? dashboardAnalyticsData?.activeIssues
                          : '-'
                      }
                    />
                  </Grid>
                  <Grid item xs={4} md={2.8} lg={3} xl={2} className='single-job-parent'>
                    <Card title='Downtime Combined (today)' data='-' />
                  </Grid>
                  <Grid item xs={4} md={3.2} lg={3} xl={4} className='single-job-parent'>
                    <CardInfo title='Production Quantity' data={dashboardAnalyticsData} />
                  </Grid>
                </Grid>
              </Box>
              <Box className='actions-parent'>
                <GlobalWrapper
                  title='My Actions'
                  height={isLGScreen ? '338px' : isMDScreen ? '380px' : '301px'}
                  Component={ActionsCard}
                  data={actionsData?.results}
                  allLink={'/my-actions'}
                />
              </Box>
              <Box className='actions-parent'>
                <GlobalWrapper
                  title='Latest Issues'
                  // height='150px'
                  Component={IssueCard}
                  data={dashboardIssueData}
                  allLink='/my-tickets'
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <CardHead
                Component={RecentActivityCard}
                height={'590px'}
                isHeightMax={true}
                title={'Recent Activity'}
                isViewAll={true}
                viewAllLink={'/recent-activity'}
                data={dashboardActivityData?.recentActivities || []}
              />
            </Grid>
          </Grid>
          <Grid
            container
            // ToDO: Add future use
            // columnSpacing={2.5}
            // rowSpacing={5}
            xl={12}
            md={12}
            lg={12}
            // ToDO: Add future use
            sx={{
              // border: '1px solid red',
              // width: '100%',
              margin: '20px 0px 0px 0px !important',
              // padding: '20px !important',
            }}
          >
            <Grid
              // item
              xl={6}
              md={6}
              lg={6}
              // className='parent-charts'
              // ToDO: Add future use
              sx={
                {
                  // paddingBottom: '50px',
                  // border: '1px solid #DEDEDE',
                  // gap: '10px !important',
                }
              }
            >
              {/* <Box sx={{ display: 'flex' }}> */}
              <BarChart
                title='Machine Performance'
                type='column'
                data={barChartDataMachine}
                height='320'
                ganttDates={ganttDates}
                setGranttDatesState={setGranttDatesState}
                setGranttDates={setGranttDates}
                isGridLine={true}
              />
            </Grid>
            <Grid
              // item
              md={6}
              xl={6}
              lg={6}
              sx={{
                padding: '0px 0px 0px 20px',
                // border: '1px solid #DEDEDE',
              }}
            >
              <BarChart
                title='Production Volumes'
                type='column'
                data={barChartProductionData}
                height='320'
                ganttDates={productionDate}
                setGranttDatesState={setProductionDate}
                setGranttDates={productionSetDate}
                isGridLine={true}
              />
              {/* </Box> */}
              {/* <Box className='parent-charts'>
                <BarChart
                  title='Production Volumes'
                  type='column'
                  data={barChartProductionData}
                  height='320'
                  ganttDates={productionDate}
                  setGranttDatesState={setProductionDate}
                  setGranttDates={productionSetDate}
                  isGridLine={true}
                />
              </Box> */}
            </Grid>
            {/* <Grid item md={6} className='parent-charts'> */}
          </Grid>
          <Grid container columnSpacing={2.5}>
            <Grid item md={12}>
              <Box className='parent-charts'>
                <GlobalWrapper
                  isTableBottomPadding={false}
                  isDashboardShowFiveRecords={true}
                  title='Ongoing Work Orders'
                  Component={TableComponent}
                  tableData={tableDataForOngoing}
                  page={pageOnGoing}
                  setPage={setPageOnGoing}
                  contant={'table'}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DashboardWrapper>
    </React.Fragment>
  );
};

export default Dashboard_New;
