import { Link } from '@material-ui/core';
import { Box, CircularProgress, Stack, styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useUploadFile } from '../../hooks/uploadHook';
import DocumentIcon from '../../assets/svg/DocumentIcon';
import DocumentRemoveIcon from '../../assets/svg/DocumentRemoveIcon';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const UploadDocument = ({
  handleChange,
  setFieldValue,
  name,
  requestNumber = '',
  rawMaterialName,
  isTaskUpload = false,
  isAttachment = false,
  createFileName = 'documentList',
  acceptTypes = '.pdf , .docx ',
  outsourcedTask = false,
}: any) => {
  const {
    mutate: uploadFile,
    data,
    isSuccess: isProfilePicUploadSuccess,
    isLoading,
  } = useUploadFile();

  const docList = data?.data?.data?.map((docDetails) => {
    return {
      docUrl: docDetails?.location,
      docName: docDetails?.originalname,
      fileType: docDetails?.mimetype,
    };
  });

  useEffect(() => {
    let arrayLength = name?.length;
    docList?.forEach((docInfo) => {
      setFieldValue(`${createFileName}[${arrayLength}]`, {
        url: docInfo?.docUrl,
        name: docInfo?.docName,
        fileType: docInfo?.fileType,
      });
      arrayLength = arrayLength + 1;
    });
  }, [isProfilePicUploadSuccess]);

  const handleFile = (e) => {
    const fileData = e.target.files;
    const formData = new FormData();
    const images: HTMLImageElement[] = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file', fileData[i]);
      formData.append('fileName', fileData[i]?.name);
      formData.append('postfix', requestNumber);
      const currDocs = new Image();
      currDocs.src = window.URL.createObjectURL(e.target.files[i]);
      currDocs.onload = () => {
        images.push(currDocs);
      };
    }
    return uploadFile(formData);
  };

  const isAttachmentRawMaterialWidth =
    location.pathname.startsWith('/add-raw-material') ||
    location.pathname.startsWith('/edit-raw-material');

  const isAttachmentWorkOrderWidth =
    location.pathname.startsWith('/add-manufacturing-order') ||
    location.pathname.startsWith('/edit-manufacturing-order') ||
    location.pathname.startsWith('/new-work-order');

  const isAttachmentTicketWidth =
    location.pathname.startsWith('/add-ticket') ||
    location.pathname.startsWith('/edit-manufacturing-order');

  const isAttachmentMachineWidth =
    location.pathname.startsWith('/add-machine') || location.pathname.startsWith('/edit-machine');

  const isQualityCheckWorkOrderWidth = location.pathname.startsWith('/quality-check-work-order');
  const startsWithSizeDetails = createFileName.startsWith('sizeDetails');

  const HeadBox = styled(Box)(() => ({
    '.ios-shared-icon': {
      marginRight: '5px',
    },
    '.padding-x': {
      padding: '0px 24px',
    },
    '.procedure-title': {
      fontSize: '14px',
      fontWeight: 600,
      padding: '24px 0px 0px 24px',
    },
    '.procedure-sub-title': {
      fontSize: '14px',
      fontWeight: 400,
      color: 'gray',
    },
    '.file-name': {
      fontWeight: 700,
      fontSize: '14px',
      padding: '0px 10px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        width: '250px !important',
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        width: '250px !important',
      },
      '@media(min-width: 1441px) and (max-width: 2560px)': {
        width: '388px !important',
      },
    },
    '.file-card': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      minWidth: '259px',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 12px 8px 12px !important',
      borderRadius: '4px',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        width: '198px !important',
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        width: isAttachmentMachineWidth ? '30%' : '90%',
      },
      '@media(min-width: 1441px) and (max-width: 2560px)': {
        minWidth: '370px !important',
        width: 'fit-content',
      },
    },
    '.file-card2': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      width: '90%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 12px 8px 12px !important',
      borderRadius: '4px',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        width: isAttachmentRawMaterialWidth
          ? '305px !important'
          : isAttachmentTicketWidth
          ? '325px !important'
          : '90% !important',
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        width: isAttachmentRawMaterialWidth
          ? '305px !important'
          : isAttachmentWorkOrderWidth
          ? '90% !important'
          : // ? '317px !important'
          isAttachmentTicketWidth
          ? '317px !important'
          : '91%, !important',
      },
      '@media(min-width: 1441px) and (max-width: 2560px)': {
        minWidth: '90% !important',
        width: '90%',
      },
    },
    '.file-wrapper-upload': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      background: '#EFF5FF',
      height: '42px',
      borderRadius: '4px',
      padding: '0px 5px',
    },
    '.remove': {
      cursor: 'pointer',
      color: '#265AA8',
    },
    '.margin-top': {
      margin: '16px 0px 0px 0px',
    },
    '.ctc-button': {
      padding: '10px 16px 10px !important',
      margin: '10px 0px 0px 25px',
      borderRadius: '4px !important',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      color: '#0160B9',
      textTransform: 'capitalize',
      border: '1px solid #0160B9',
      cursor: 'pointer',
    },
    '.isTaskUpload': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '3px 11px !important',
      fontSize: '10px !important',
      fontWeight: '400 !important',
      borderRadius: '8px',
      gap: '10px !important',
      border: '1px dashed #A6A6A6',
      color: '#121212',
      backgroundColor: '#F6F6F6',
      textTransform: 'capitalize',
    },
    '.isQualityCheckWorkOrderWidth': {
      fontSize: '10px',
      padding: '8.5px 150px 8.5px 0px',
      height: '100% !important',
      '@media(min-width: 425px) and (max-width: 1024px)': {
        padding: '8.5px 0px 8.5px 0px',
      },
      '@media(min-width: 1025px) and (max-width: 1440px)': {
        padding: '8.5px 132px 8.5px 0px',
      },
    },
  }));
  return (
    <React.Fragment>
      <HeadBox>
        {!isAttachment && (
          <Typography
            className={
              rawMaterialName === 'Attachment'
                ? 'Attachment'
                : isTaskUpload === true
                ? ''
                : 'procedure-title'
            }
          >
            {rawMaterialName === 'Attachment'
              ? 'Attachment'
              : isTaskUpload === true
              ? ''
              : 'Maintenance Procedure'}{' '}
            {}
            <span className='procedure-sub-title'>
              {isTaskUpload === true ? '' : 'Supported files: .pdf, .doc file only'}
            </span>
          </Typography>
        )}
        {isLoading && <CircularProgress variant={isLoading ? 'indeterminate' : 'determinate'} />}
        {!name?.length ? (
          <Stack direction='row' alignItems='center' spacing={2}>
            <Link underline='none' component='label' className='upload-data'>
              {isAttachment ? (
                <Box className='file-wrapper-upload'>
                  <Typography>
                    {startsWithSizeDetails ? 'Upload Image' : 'Upload Attachment'}
                  </Typography>
                  <FileUploadOutlinedIcon />
                </Box>
              ) : (
                <Typography className={isTaskUpload === true ? 'isTaskUpload' : 'ctc-button'}>
                  {isTaskUpload === true ? (
                    <Box className='' style={{ color: '#0160B9' }}>
                      <FileUploadOutlinedIcon />
                    </Box>
                  ) : (
                    ''
                  )}
                  {rawMaterialName === 'Attachment' ? (
                    'Upload Attachment'
                  ) : isTaskUpload === true ? (
                    outsourcedTask ? (
                      <Box className='isQualityCheckWorkOrderWidth'>Attachments</Box>
                    ) : isQualityCheckWorkOrderWidth ? (
                      <Box className='isQualityCheckWorkOrderWidth'>
                        Supported format (.Jpg, .pdf, .png)
                      </Box>
                    ) : (
                      'Supported format (.Jpg, .pdf, .png)'
                    )
                  ) : (
                    'Upload from desktop'
                  )}
                </Typography>
              )}

              <input
                hidden
                accept={acceptTypes}
                multiple
                type='file'
                onChange={(e) => {
                  handleFile(e);
                  handleChange(e);
                }}
              />
            </Link>
          </Stack>
        ) : (
          <Box
            className='file-wrapper'
            sx={{
              margin: createFileName === 'documentList' ? '8px 0px 0px 25px' : '',
            }}
          >
            {name?.length > 0 &&
              name?.map((docInfo, ind) => {
                return (
                  <Box
                    className={
                      isTaskUpload === true
                        ? 'file-card2'
                        : isAttachment === true
                        ? 'file-card2'
                        : 'file-card'
                    }
                    key={ind}
                  >
                    <DocumentIcon />
                    <Typography className='file-name'>{docInfo?.name}</Typography>
                    <Box
                      className='remove'
                      onClick={() => {
                        const filteredDocs = name?.filter((doc) => {
                          return doc.name !== docInfo?.name;
                        });
                        setFieldValue(
                          `${createFileName}`,
                          filteredDocs.length > 0 ? filteredDocs : []
                        );
                      }}
                    >
                      <DocumentRemoveIcon />
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </HeadBox>
    </React.Fragment>
  );
};

export default UploadDocument;
